$bgc: #5864FF;
.SideMenu{
   position: relative;
   height: 100vh;
   font-size: 18px;
   color: white;
   background-color: $bgc;
   overflow: hidden;

   >ul {
      // max-height: 100vh - $height * 2;
      overflow-y: auto;
      overflow-x: hidden;
      border: none;
   }

   .header{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $bgc;
      z-index: 9;
      border-bottom:1px solid rgba(116, 150, 255, 1);

      >img {
         width: 4.5vh;
      }

      .content{
         margin-left: 1vw;
         margin-right: 4vw;
         text-align: left;
         $size: 2.4vh;
         >p:first-child{
            font-size: $size;
            font-weight: 500;
            line-height: $size * 1.2;
         }
         >p:last-child{
            font-size: $size * 0.6;
            font-weight:500;
         }
      }
      >div:last-child{
         position: absolute;
         cursor: pointer;
         width: 2.8vh;
         height: 2.8vh;
         right: 0;
         border-radius: 50%; 
         background-color: #ffffff2e;
         transform: translateX(50%);

         >i {
            color: #fff;
            position: absolute;
            left: 0.5vh;
            top: 31%;
            font-size: 1vh;
            transform: rotate(0deg);
            transition: transform 0.3s ease 0s;
         }
      }
   }
   .ant-menu,
   .ant-menu .ant-menu-sub{
      color: #B3CBEC;
      background-color: $bgc !important;
      box-shadow: none !important;
      transition: none; // 防止抖动
   }
   
   .ant-menu-root {
      > .ant-menu-item {
         transition: width 2s ease 0s;
      }
      .ant-menu-item .anticon,
      .ant-menu-submenu-title .anticon {
         font-size: 16px;
      }

      &.ant-menu-inline-collapsed{
         // width: 100px;
         
         .ant-menu-item .anticon,
         .ant-menu-submenu-title .anticon {
            font-size: 20px;
         }
      }
   }
   $itemH: 4.2vh;
   $minItemH: 32px;
   .ant-menu-vertical > .ant-menu-item {
      line-height: $itemH;
      height: $itemH;
      min-height: $minItemH;
   }
   .ant-menu-inline {
      .ant-menu-submenu-title {
         // font-size: 1.8vh;
         font-size: 16px;
      }
      > .ant-menu-item {
         line-height: $itemH !important;
      }
      .ant-menu-item {
         height: $itemH !important;
         min-height: $minItemH;
         // font-size: 1.6vh;
         font-size: 14px;
         width: 100%;
      }
   }

   .ant-menu-inline,
   .ant-menu-vertical {
      .ant-menu-item:active,
      .ant-menu-submenu-title:active {
         background: none !important;
      }
   }
   .ant-menu-submenu-selected,
   .ant-menu-submenu-title:hover,
   .ant-menu-item-selected,
   .ant-menu-item:hover{
      color: #FEFEFE !important;
   }
   .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      position: relative;
      background-color: #6973FF !important;

      &:before {
         content: "";
         position: absolute;
         width: 4px;
         height: 100%;
         background-color: #fff;
         left: 0;
         top: 0;
      }
      &:after {
         content: none;
      }
   }
   .ant-menu-submenu-arrow{
      &::before,
      &::after{
         background: #B3CBEC !important;
      }
      
      &::after{
         transform: rotate(-45deg) translateX(4px) scale(2) !important;
      }
      &::before{
         transform: rotate(45deg) translateX(-4px) scale(2) !important;
      }
   }
   .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
      transform: translateY(-5px); // 展开式向上偏移，保证图标位置一致
      &::after{
         transform: rotate(-45deg) translateX(-5px) scale(2) !important;
      }
      &::before{
         transform: rotate(45deg) translateX(5px) scale(2) !important;
      }
   }
}