@import url("~antd/dist/antd.css");

$themeColor : #5864FF;

.ant-btn-primary {
  background-color: $themeColor;
  border-color: $themeColor;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
textarea {
  font: 12px/1.5 'Microsoft YaHei', Serif, \5b8b\4f53;
  font-family: 'Microsoft YaHei'
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

ul,
ol {
  list-style: none;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@mixin wid_hei($wid:100%, $hei:100%) {
  width: $wid;
  height: $hei;
}

.wh100 {
  @include wid_hei(100vw, 100vh);
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.container {
  margin: 0px;
  padding: 0px;
  width: 100% !important;
}

.container:after {
  height: 0px;
}

.disF {
  display: flex;
}

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.login-error {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bold;
  color: red;
}

.echarts-for-react {
  height: 100%;
}

.yahei {
  font-family: "microsoft yahei";
}

ul li {
  list-style: none !important;
}

.OFH {
  overflow: hidden;
}

.fz12 {
  font-size: 12px;
}

.fz14 {
  font-size: 14px;
}

.fz16 {
  font-size: 16px;
}
.fontBold{
 font-weight: bold;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.tal {
  text-align: left;
}


.ml15 {
  margin-left: 15px
}

.mr10 {
  margin-right: 10px
}

.mr15 {
  margin-right: 15px
}

.ml5 {
  margin-left: 5px
}

.mr1vw {
  margin-right: 1vw
}

.ml1vw {
  margin-left: 1vw
}

.pb10 {
  padding-bottom: 10px;
}

.mr5 {
  margin-right: 5px
}
.mr8{
  margin-right:8px;
}

.mt5 {
  margin-top: 5px
}

.mt10 {
  margin-top: 10px
}

.mt15 {
  margin-top: 15px
}

.mb5 {
  margin-bottom: 5px
}

.mb10 {
  margin-bottom: 10px
}

.mb15 {
  margin-bottom: 15px
}

.mb20 {
  margin-bottom: 20px
}

.w220{
  width: 220px;
}

.footer {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px #e8e8e8 solid;
}

/****** 字体包引入 start ******/
@font-face {
  font-family: "pingfang";
  src: url(./assets/fonts/PingFang-SC-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "04b_03b";
  src: url(./assets/fonts/04B_03B_.ttf) format("truetype");
}

@font-face {
  font-family: "SourceHanSansCN";
  src: url(./assets/fonts/SourceHanSansCNRegular.ttf) format("truetype");
}

@font-face {
  font-family: "DIN";
  src: url(./assets/fonts/DINPro-Medium.otf) format("opentype");
}

.SourceHanSansCN {
  font-family: 'SourceHanSansCN' !important;
}

.DIN {
  font-family: 'DIN';
}



/****** 字体包引入 end ******/

/* 滚动条样式 start */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px hsla(0, 0%, 100%, .3);
  -webkit-box-shadow: inset 0 0 6px hsla(0, 0%, 100%, .3);
  background-color: #d5d6d2;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px hsla(0, 0%, 88%, .3);
  -webkit-box-shadow: inset 0 0 6px hsla(0, 0%, 88%, .3);
  background-color: #bbb;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #bebebe;
}

::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #bebebe;
}

/* 滚动条样式 start */


/* ant 下拉菜單樣式 start */
.ant-dropdown,
.ant-dropdown-menu-submenu {
  ul {
    border: 1px solid #65ffff;
    box-sizing: border-box;
    box-shadow: 0 0 5px #a1eaea;
    background-color: rgba(1, 35, 62, .9);
    margin-top: 5px;

    li {
      font-size: 14px;
      color: #fff;
      /* padding: 10px 20px; */
      text-align: left
    }
  }
}

.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-title i {
  color: #fff !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
}

/* ant 下拉菜單樣式 end */

/* 主框架样式 start */
// 功能框架样式
.MainFunContainer {
  $HeaderH: 8.7vh;

  display: flex;
  background-color: #f5f8fa;
  font-family: 'SourceHanSansCN' !important;

  $SideMenuW: 18.5vw;

  .SideMenu {
    // position: fixed;
    width: $SideMenuW;
    min-width: 14rem;

    .header {
      height: $HeaderH;
    }

    >.ant-menu-root {
      height: 100vh - $HeaderH;
    }
  }

  .MainContent {
    height: 100vh;
    width: 100%;
    min-width: 960px;

    >.Head {
      height: $HeaderH;
    }

    &.isFun {
      >div:last-child {
        height: 100vh - $HeaderH;
        overflow-y: auto;
      }
    }
  }

  .BoardContent {
    height: 100vh;
    width: 100%;
    min-width: 960px;
    >div:last-child {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
}

/* 主框架样式 end */

/* 样式改写 start */
.ant-spin-nested-loading>div>.ant-spin {
  max-height: none;
}

// .ant-modal-wrap .ant-modal{
//   // top: 50%;
//   // transform: translateY(-50%);
// }

.poi-default {

  // 下拉菜单item样式
  &.ant-select-dropdown-menu-item {
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  // 模态框样式
  &.ant-modal {
    min-width: 700px;
    font-family: 'SourceHanSansCN';

    .ant-modal-title {
      font-size: 2.2vh;
      font-weight: 500;
    }

    .ant-modal-body {
      font-size: 1.8vh;

      .ant-switch-checked {
        background-color: #61CE92;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  // 表格样式
  .ant-table {
    .ant-table-content {

      // 表格样式
      .ant-table-scroll .ant-table-header {
        overflow: hidden !important;

        tr>th {
          color: #fff;
          background-color: rgba(0, 81, 212, 1);
        }
      }

      .ant-table-tbody {
        overflow-y: auto;

        tr:nth-child(even)>td {
          background: rgba(245, 247, 251, 1);
        }
      }
    }
  }

  &.ant-radio-group {
    .ant-radio-button-wrapper{
      background:rgba(255,255,255,1);
      border:1px solid rgba(221,226,238,1);
      color: #3C4353;
    }

    .ant-radio-button-wrapper-checked{
      background:rgba(88,100,255,1);
      border:1px solid rgba(88,100,255,1);
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }

  &.ant-popover {
    font-family: 'SourceHanSansCN';
    max-width: 550px;
    position: relative;
    overflow: hidden;
    $footerH: 80px;

    .ant-popover-content {
      box-shadow:0px 0px 17px 1px rgba(221,226,238,1);
    }

    .ant-popover-title {
      line-height: 50px;
      color: #3C4353;
      font-size:18px;
      font-weight: 800;
    }
    .ant-popover-inner-content{
      padding-bottom: $footerH;
      >span {
        color: #9EA6B2;
        font-size: 12px;
      }
      >h2{
        color: #3C4353;
        margin-top: 5px;
        margin-bottom: 5px;

        &:nth-of-type(n+2){
          margin-top: 15px
        }
      }
      >p{
        color:#3C4353
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: $footerH;
      background-size: 100% 100%;
      background-image: url(./assets/images/footer.png);

    }
  }

}

/* 样式改写 end */

// table  样式
.main-tables {
  box-shadow: 0 1px 6px 0 rgba(198, 198, 199, 0.28);
  // padding-bottom: 63px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #fff;

  .ant-table-thead th {
    background-color: rgba(247, 248, 249, 1);
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3C4353;
  }

  .ant-table-tbody {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(158, 166, 178, 1);
  }
}


$VH20: 1.85vh; // 20px

.app-main {
  padding: $VH20 $VH20 $VH20; // $VH20 $VH20*2 $VH20;
  display: flex;
  flex-direction: column;
  height: 91.3vh;
  overflow-y: auto;

  .tableBtn {
    display: flex;
    justify-content: space-around;
    color: #2db7f5;

    >span {
      cursor: pointer;
      margin-left: 6px;
    }

    .del {
      color: red;
    }
  }
}

.state-color {
  color: red;
}
.danger {
  color: #ff4d4f;

  &:hover,
  &:focus {
    color: #ff7875;
  }
}

// .ant-table-row-level-0:hover {
//   box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
// }

.line {
  width: 100%;
  border-bottom: 2px solid #F2F6FA;
}

.cp {
  cursor: pointer;
}

.customFilterDropdown {
  &>* {
    padding: 0px 15px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      background-color: #e6f7ff;
    }

    &.selected {
      background-color: #e6f7ff;
    }
  }
}


.ant-modal-footer {
  border-top: none;
  background: #FAFAFB;
}

.cloud_5 {
  margin: 20px;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;

  .btn-link {
    color: #384454;
    >span {
      margin-left: 0;
    }
  }

}

.cloud_5_drawer {
  .ant-checkbox-wrapper {
      display: block;
      margin-bottom: 10px;
  }
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #F0F4F8;
}

.ant-btn-link {
  color: $themeColor;

  &:hover,
  &:focus {
    color: $themeColor;;
  }
}

.formItem {
  display: flex;
  margin-bottom: 15px;
  align-items: center;

  >span {
      display: inline-block;
      width: 100px;
      padding-right: 15px;
      text-align: right;

      &.require:before {
          content: '*';
          color: red;
          padding-right: 5px;
      }
  }
}
