$themeColor : #5864FF;

.Head {
  position: relative;
  background-color: #fff;
  // display: flex;
  // justify-content: space-around;
  border-bottom: 1px solid #eaedf7;

  .left {
    // display: inline-block;
    height: 8.7vh;
    line-height: 8.7vh;
    float: left;
    padding-left: 15px;
    text-align: left;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #3C4353;
    display: flex;
    align-items: center;
  }

  .right {
    line-height: 8.7vh;
    height: 8.7vh;
    float: right;
    display: flex;
    align-items: center;
    margin-right: 30px;

    .badge,
    .logout,
    .user-name {
      display: inline-block;
      margin-left: 26px;
    }

    .user-name {
      color: #838A9D;
      font-size: 18px;
      height: 8.7vh;
      line-height: 8.7vh;
    }

    .logout {
      height: 42px;
      position: relative;
      display: flex;
      align-items: center;

      .imgIcon {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        overflow: hidden;

      }

      img {
        vertical-align: top;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

    }
  }
}
